// Commonly used web colors
$black: #000000; // Black
$white: #ffffff; // White
$yellow: #FFE600; // Yellow
$success-green: #6ACEB0; // Sampled from Sonic Mania cover art
$error-red: #FE4064; // Sampled from Sonic 3 Knuckles sprite
$warning-red: #FF2B2B; // Sampled from Sonic Mania Knuckles sprite
$gold-star: #FF9800; // Sampled from Sonic Mania Tails sprite
$red-bookmark: $warning-red;

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;

// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$no-gap-breakpoint: 1175px;

$font-sans-serif: 'mastodon-font-sans-serif' !default;
$font-display: 'mastodon-font-display' !default;
$font-monospace: 'mastodon-font-monospace' !default;

// Avatar border size (8% default, 100% for rounded avatars)
$ui-avatar-border-size: 8%;

// More variables
$dismiss-overlay-width: 4rem;

// Disable collapsing on statuses by @sonicpodcast
.status[data-status-by="@sonicpodcast"] {
  &.collapsed .status__content {
    height: auto;
    overflow: visible;
    padding-top: 5px;
    text-overflow: initial;

    &::after {
      display: none !important;
    }
  }

  & .status__collapse-button {
    display: none !important;
  }
}
