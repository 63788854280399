.tabs-bar__wrapper {
    border-bottom: 3px solid $yellow;

    .column-header {
        background-image: url('flavours/glitch/styles/speedlines/checker_blue.jpg');
	background-position: 50% 50%;
	background-repeat: repeat;
	background-size: 5%;

	&__back-button, & > button {
	    font-family: $font-display;
            background: none;
	    color: $white;
	    text-shadow: $black 2px 2px;

	    i[role="img"] {
                color: $yellow;
	    }
	}

	&__button {
            background: none;

	    &.active:hover {
                background: none;
	    }

	    i[role="img"] {
                background: $ui-highlight-color;
		padding: 4px;
		border-radius: 4px;
		border: 1px $black solid;
                box-shadow: $black 0px 3px 0px 1px;
		color: $yellow;
		text-shadow: $black 0px 2px;

		&:hover {
                    color: $white;
		}
	    }

	    &.active i[role="img"] {
                background: $white;
		color: $ui-base-color;
		text-shadow: unset;
	    }
	}

	.icon-with-badge__badge {
		left: unset;
		right: -12px;
	}
    }
}

.icon-with-badge__badge {
    font-family: $font-display;
}

.button {
    text-transform: uppercase !important;
    font-family: $font-display;
    background: $yellow;
    color: darken($ui-base-color, 7%);

    &:hover {
        background: $ui-highlight-color;
	color: $white;
    }

    &:disabled {
	background: none;
	border: $yellow 1px solid;
	color: lighten($ui-base-color, 60%);
	opacity: 0.6;
    }
}

.column-link {
    font-family: $font-display;
    text-shadow: $black 0px 2px;

    &.active {
	color: $yellow;
    }
}

.status__content {
    .status__content__spoiler-link {
        background: darken($white, 12%);
    
        &:hover {
            background: $white;
        }
        
	&:focus {
            background: $white;
        }
    }
}

.status__content__spoiler-link {
    background: darken($white, 12%);

    &:hover {
        background: $white;
    }
    
    &:focus {
        background: $white;
    }
}

.status__content a,
.link-footer a,
.reply-indicator__content a,
.status__content__read-more-button {
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.mention {
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      span {
        text-decoration: none;
      }
    }
  }
}

.compose-form__poll-wrapper .button.button-secondary,
.language-dropdown__dropdown__results__item__common-name,
.compose-form .icon-button,
.compose-form .text-icon-button {
  color: $white;
}

.compose-form .autosuggest-textarea__textarea::placeholder,
.compose-form .spoiler-input__input::placeholder,
.report-dialog-modal__textarea::placeholder {
  color: rgba(255,255,255,0.8);
}

.character-counter {
    color: $ui-highlight-color;
}
